import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { getConsolidatedConfigSettings } from '../../utils/auth'
import { processMentions } from '../../utils/general'

const dayWidth = 20

const generateDateRange = (startDate, endDate) => {
  const start = new Date(startDate)
  const end = new Date(endDate)
  const dateArray = []
  let currentDate = new Date(start)
  while (currentDate <= end) {
    dateArray.push(new Date(currentDate))
    currentDate.setDate(currentDate.getDate() + 1)
  }
  return dateArray
}

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const groupDatesByMonthAndYear = (dates, languageSettings) => {
  return dates.reduce((acc, date) => {
    const year = date.getFullYear()
    const month = capitalizeFirstLetter(
      date.toLocaleString(languageSettings === 'es' ? 'es-ES' : 'default', { month: 'long' }),
    )
    if (!acc[year]) {
      acc[year] = {}
    }
    if (!acc[year][month]) {
      acc[year][month] = []
    }
    acc[year][month].push(date.getDate())
    return acc
  }, {})
}

const renderGanttMonthDayDiv = (year, month, days) => {
  const today = new Date()
  today.setHours(0, 0, 0, 0) // Set to start of day

  return (
    <div
      key={`${year}-${month}`}
      className="gantt-month-day"
      style={{
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #ccc',
        borderRight: 'none',
      }}
    >
      <div
        className="gantt-month"
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          width: `${days.length * dayWidth}px`,
          borderBottom: '1px solid #ccc',
          height: '20px',
          lineHeight: '20px',
        }}
      >
        {`${month} ${year}`}
      </div>
      <div className="gantt-days" style={{ display: 'flex', flexWrap: 'nowrap' }}>
        {days.map((day) => {
          const currentDate = new Date(year, new Date(`${month} 1, ${year}`).getMonth(), day)
          currentDate.setHours(0, 0, 0, 0) // Set to start of day
          const isToday = currentDate.getTime() === today.getTime()
          return (
            <div
              key={`${year}-${month}-${day}`}
              className={`gantt-day ${isToday ? 'today' : ''}`}
              style={{
                width: `${dayWidth}px`,
                height: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRight: '1px solid #ccc',
                fontSize: '12px',
                position: 'relative',
                boxSizing: 'border-box',
              }}
            >
              {day}
              {isToday && (
                <div
                  className="gantt-today-marker"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(255, 0, 0, 0.1)',
                    zIndex: 1,
                  }}
                ></div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

const GanttChart = ({ data, isFromSummaryOfObjectives }) => {
  const { t } = useTranslation(['Common'])
  const languageSettings = getConsolidatedConfigSettings('language_settings')
  const [collapsed, setCollapsed] = useState({})
  const [rangeStartDate, setRangeStartDate] = useState(null)
  const [rangeEndDate, setRangeEndDate] = useState(null)
  const [viewStartDate, setViewStartDate] = useState(null)
  const [viewEndDate, setViewEndDate] = useState(null)
  const [timeRange, setTimeRange] = useState('all')
  const [hoveredItemId, setHoveredItemId] = useState(null)
  const [hoveredTaskId, setHoveredTaskId] = useState(null)
  const [hoveredTaskType, setHoveredTaskType] = useState(null)
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 })
  const [taskTooltipPosition, setTaskTooltipPosition] = useState({ top: 0, left: 0 })
  const [dayWidth, setDayWidth] = useState(20)
  const timelineRef = useRef(null)
  const bodyRef = useRef(null)
  const timelineBodyRef = useRef(null)
  const taskListRef = useRef(null)
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    if (taskListRef.current && bodyRef.current && timelineBodyRef.current) {
      setIsReady(true)
    }
  }, [])

  useEffect(() => {
    if (!isReady) return

    const taskList = taskListRef.current
    const timeline = bodyRef.current
    let isScrolling = false

    const syncScroll = (primary, secondary) => {
      if (!isScrolling) {
        isScrolling = true
        secondary.scrollTop = primary.scrollTop
        setTimeout(() => {
          isScrolling = false
        }, 10)
      }
    }

    const handleScrollTaskList = () => syncScroll(taskList, timeline)
    const handleScrollTimeline = () => syncScroll(timeline, taskList)

    taskList.addEventListener('scroll', handleScrollTaskList)
    timeline.addEventListener('scroll', handleScrollTimeline)

    return () => {
      taskList.removeEventListener('scroll', handleScrollTaskList)
      timeline.removeEventListener('scroll', handleScrollTimeline)
    }
  }, [isReady])

  useEffect(() => {
    // console.log('viewStartDate:', viewStartDate?.toISOString());
    // console.log('viewEndDate:', viewEndDate?.toISOString());
    // console.log('dayWidth:', dayWidth);
    // console.log('Today line position:', getTodayLinePosition());
    // console.log('Current date:', new Date().toISOString());
  }, [viewStartDate, viewEndDate, dayWidth])

  const toggleCollapse = (id) => {
    setCollapsed((prev) => ({ ...prev, [id]: !prev[id] }))
  }

  const getTodayLinePosition = () => {
    if (!viewStartDate || !viewEndDate) return null

    const today = new Date()
    today.setHours(0, 0, 0, 0) // Set to start of day
    const viewStart = new Date(viewStartDate)
    viewStart.setHours(0, 0, 0, 0) // Set to start of day

    if (today < viewStart || today > viewEndDate) {
      return null
    }

    const millisecondsPerDay = 24 * 60 * 60 * 1000
    const daysFromStart = Math.floor((today - viewStart) / millisecondsPerDay)
    return daysFromStart * dayWidth + dayWidth / 2
  }

  const flattenData = (items, depth = 0) => {
    if (!Array.isArray(items)) return []

    return items.reduce((acc, item) => {
      if (item && typeof item === 'object') {
        const isCollapsed = collapsed[item.id]
        acc.push({ ...item, depth, isVisible: true })
        if (Array.isArray(item.children) && item.children.length > 0 && !isCollapsed) {
          acc.push(...flattenData(item.children, depth + 1))
        }
      }
      return acc
    }, [])
  }

  const parseDate = (dateString) => {
    if (!dateString) return null
    if (typeof dateString !== 'string') return null
    const [year, month, day] = dateString.split('-').map(Number)
    return new Date(year, month - 1, day)
  }

  const formatDate = (date) => {
    if (!date) return ''
    return date.toISOString().split('T')[0]
  }

  const diffDays = (date1, date2) => {
    const timeDiff = date2.getTime() - date1.getTime()
    return Math.ceil(timeDiff / (1000 * 3600 * 24))
  }

  const colors = {
    objective: 'rgb(0, 51, 102)',
    action: 'rgb(51, 102, 153)',
    subactivity: 'rgb(102, 153, 204)',
    // We only use these other levels for custom gantt
    subactivity1: 'rgb(153, 204, 255)',
    subactivity2: 'rgb(204, 229, 255)',
    subactivity3: 'rgb(229, 242, 255)',
    subactivity4: 'rgb(242, 248, 255)',
    subactivity5: 'rgb(248, 251, 255)',
  }

  const flatData = flattenData(data)

  useEffect(() => {
    if (flatData.length > 0) {
      const allDates = flatData
        .flatMap((item) => [parseDate(item.startDate), parseDate(item.endDate)])
        .filter(Boolean)

      if (allDates.length > 0) {
        const minDate = new Date(Math.min(...allDates))
        const maxDate = new Date(Math.max(...allDates))
        setRangeStartDate(minDate)
        setRangeEndDate(maxDate)
        setViewStartDate(minDate)
        setViewEndDate(maxDate)

        // If there are minDate and maxDate, set timeRange to 'all'
        // Otherwise, set it to 'thisQuarter'
        setTimeRange(allDates.length > 1 ? 'all' : 'thisQuarter')
      } else {
        // If there are no dates at all, set timeRange to 'thisQuarter'
        setTimeRange('thisQuarter')

        // Set view to current quarter
        const today = new Date()
        const quarterStart = new Date(today.getFullYear(), Math.floor(today.getMonth() / 3) * 3, 1)
        const quarterEnd = new Date(quarterStart)
        quarterEnd.setMonth(quarterEnd.getMonth() + 3)
        quarterEnd.setDate(0)

        setViewStartDate(quarterStart)
        setViewEndDate(quarterEnd)
      }
    }
  }, [data])

  useEffect(() => {
    const handleScroll = () => {
      if (timelineRef.current && bodyRef.current) {
        timelineRef.current.scrollLeft = bodyRef.current.scrollLeft
      }
    }

    const bodyElement = bodyRef.current
    if (bodyElement) {
      bodyElement.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (bodyElement) {
        bodyElement.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  if (flatData.length === 0) {
    return <div>{t('ganttChartModal.noGanttDataAvailable')}</div>
  }

  const updateTimeRange = (range) => {
    setTimeRange(range)
    const today = new Date()
    let newStartDate, newEndDate

    const getFirstDayOfMonth = (date) => new Date(date.getFullYear(), date.getMonth(), 1)
    const addMonths = (date, months) => new Date(date.getFullYear(), date.getMonth() + months, 1)

    switch (range) {
      case 'all':
        newStartDate = new Date(rangeStartDate)
        newEndDate = new Date(rangeEndDate)
        break
      case 'nextMonth':
        newStartDate = addMonths(getFirstDayOfMonth(today), 1)
        newEndDate = new Date(newStartDate)
        newEndDate.setMonth(newEndDate.getMonth() + 1)
        newEndDate.setDate(0) // Set to the last day of the next month
        break
      case 'thisQuarter':
        newStartDate = getFirstDayOfMonth(
          new Date(today.getFullYear(), Math.floor(today.getMonth() / 3) * 3, 1),
        )
        newEndDate = new Date(newStartDate)
        newEndDate.setMonth(newEndDate.getMonth() + 3)
        newEndDate.setDate(newEndDate.getDate() - 1)
        break
      case '6months':
        newStartDate = getFirstDayOfMonth(today)
        newEndDate = addMonths(newStartDate, 6)
        newEndDate.setDate(newEndDate.getDate() - 1)
        break
      case '12months':
        newStartDate = getFirstDayOfMonth(today)
        newEndDate = addMonths(newStartDate, 12)
        newEndDate.setDate(newEndDate.getDate() - 1)
        break
      case 'custom':
        // For custom, we keep the current view dates
        newStartDate = viewStartDate
        newEndDate = viewEndDate
        break
      default:
        return
    }

    setViewStartDate(newStartDate)
    setViewEndDate(newEndDate)
  }

  const getBarStyle = (item) => {
    const itemStart = parseDate(item.startDate)
    const itemEnd = parseDate(item.endDate)

    // If there are no dates at all, don't display anything
    if (!itemStart && !itemEnd) return null

    // Check if the item is completely outside the current view range
    if ((itemEnd && itemEnd < viewStartDate) || (itemStart && itemStart > viewEndDate)) {
      return null
    }

    // Handle case with only start date
    if (itemStart && !itemEnd) {
      // Only show if the start date is within the view range
      if (itemStart < viewStartDate || itemStart > viewEndDate) return null

      const startOffset = Math.max(diffDays(viewStartDate, itemStart), 0)
      return {
        left: `${startOffset * dayWidth}px`,
        width: '0',
        height: '0',
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderBottom: '20px solid gray',
        backgroundColor: 'transparent',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
      }
    }

    // Handle case with only end date
    if (!itemStart && itemEnd) {
      // Only show if the end date is within the view range
      if (itemEnd < viewStartDate || itemEnd > viewEndDate) return null

      const endOffset = Math.max(diffDays(viewStartDate, itemEnd), 0)
      return {
        left: `${endOffset * dayWidth}px`,
        width: '0',
        height: '0',
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderTop: '20px solid gray',
        backgroundColor: 'transparent',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
      }
    }

    // Handle case with both start and end dates
    const startOffset = Math.max(diffDays(viewStartDate, itemStart), 0)
    const endDate = itemEnd > viewEndDate ? viewEndDate : itemEnd
    const duration = Math.max(
      diffDays(viewStartDate > itemStart ? viewStartDate : itemStart, endDate) + 1,
      0,
    )

    return {
      left: `${startOffset * dayWidth}px`,
      width: `${duration * dayWidth}px`,
      backgroundColor: colors[item.type],
      height: '20px',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
    }
  }

  const dateRange = generateDateRange(viewStartDate, viewEndDate)
  const groupedDates = groupDatesByMonthAndYear(dateRange, languageSettings)
  const totalWidth =
    Object.values(groupedDates).reduce(
      (yearSum, months) =>
        yearSum +
        Object.values(months).reduce((monthSum, days) => monthSum + days.length * dayWidth, 0),
      0,
    ) + 20

  const handleTaskMouseEnter = (e, item) => {
    const element = e.currentTarget
    const rect = element.getBoundingClientRect()

    setHoveredTaskId(item.id)
    setHoveredTaskType(item.type)
    setTaskTooltipPosition({ top: rect.top - 150, left: rect.left + 165 })
  }

  const handleMouseMove = (event, item) => {
    try {
      if (!timelineBodyRef.current) {
        console.warn('Timeline body ref is not available')
        return
      }

      const timelineBodyRect = timelineBodyRef.current.getBoundingClientRect()

      const tooltipHeight = 80 // Adjust as needed
      const tooltipWidth = 200 // Adjust as needed
      const offset = 10 // Offset from the cursor

      // Get mouse position relative to the timeline body
      let top = event.clientY - timelineBodyRect.top
      let left = event.clientX - timelineBodyRect.left

      // Adjust tooltip position based on available space
      if (left + tooltipWidth + offset > timelineBodyRect.width) {
        // Not enough space on the right, position to the left of the cursor
        left = left - tooltipWidth - offset
      } else {
        // Position to the right of the cursor
        left = left + offset
      }

      if (top + tooltipHeight > timelineBodyRect.height) {
        // Not enough space below, position above the cursor
        top = top - tooltipHeight - offset
      } else {
        // Position below the cursor
        top = top + offset
      }

      // Ensure the tooltip stays within the timeline body
      top = Math.max(0, Math.min(top, timelineBodyRect.height - tooltipHeight))
      left = Math.max(0, Math.min(left, timelineBodyRect.width - tooltipWidth))

      setTooltipPosition({ top, left })
      setHoveredItemId(item.id)
    } catch (error) {
      console.error('Error in handleMouseMove:', error)
      setTooltipPosition({ top: 0, left: 0 })
      setHoveredItemId(item.id)
    }
  }

  return (
    <div className="gantt-chart">
      <div className="gantt-controls">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
            fontWeight: 'bold',
            width: '16rem',
            gap: '0.5rem',
          }}
        >
          <span>{t('ganttChartModal.selectChartTimeframe')}:</span>
          <select value={timeRange} onChange={(e) => updateTimeRange(e.target.value)}>
            <option value="all">{t('ganttChartModal.all')}</option>
            <option value="nextMonth">{t('ganttChartModal.nextMonth')}</option>
            <option value="thisQuarter">{t('ganttChartModal.thisQuarter')}</option>
            <option value="6months">{t('ganttChartModal.6months')}</option>
            <option value="12months">{t('ganttChartModal.12months')}</option>
            <option value="custom">{t('ganttChartModal.custom')}</option>
          </select>
        </div>
        {timeRange === 'custom' && (
          <>
            <input
              type="date"
              value={formatDate(viewStartDate)}
              onChange={(e) => {
                const newDate = parseDate(e.target.value)
                if (newDate) setViewStartDate(newDate)
              }}
            />
            <input
              type="date"
              value={formatDate(viewEndDate)}
              onChange={(e) => {
                const newDate = parseDate(e.target.value)
                if (newDate) setViewEndDate(newDate)
              }}
            />
          </>
        )}
        {/* <span>
          Full Date Range: {formatDate(rangeStartDate)} - {formatDate(rangeEndDate)}
        </span> */}
      </div>
      <div className="gantt-content">
        <div className="gantt-task-list" ref={taskListRef}>
          <div className="gantt-title">{t('ganttChartModal.task')}</div>
          {flatData.map((item) => (
            <div
              key={item.id + '-' + item.type}
              className="gantt-task-item"
              style={{ paddingLeft: `${item.depth * 20}px` }}
              onMouseMove={(e) => handleTaskMouseEnter(e, item)}
              onMouseLeave={() => {
                setHoveredTaskId(null)
                setHoveredTaskType(null)
              }}
            >
              <span onClick={() => toggleCollapse(item.id)}>
                {item.children && item.children.length > 0 && (
                  <span className="collapse-icon">{collapsed[item.id] ? '▶' : '▼'}</span>
                )}
                {processMentions(item.text)}
              </span>
            </div>
          ))}
          {hoveredTaskId && (
            <div
              className="gantt-task-tooltip"
              style={{
                top: `${taskTooltipPosition.top}px`,
                left: `${taskTooltipPosition.left}px`,
              }}
            >
              <div className="gantt-task-tooltip-content">
                <div className="gantt-tooltip-row">
                  <span className="gantt-tooltip-text">
                    <b>
                      {' '}
                      {processMentions(
                        flatData.find(
                          (item) => item.id === hoveredTaskId && item.type === hoveredTaskType,
                        )?.text,
                      )}
                    </b>

                    {isFromSummaryOfObjectives &&
                      flatData.find((item) => item.id === hoveredTaskId)?.ownerName &&
                      ' - ' + flatData.find((item) => item.id === hoveredTaskId)?.ownerName}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="gantt-timeline" ref={bodyRef}>
          <div className="gantt-timeline-inner" style={{ width: `${totalWidth}px` }}>
            <div className="gantt-header" ref={timelineRef}>
              {Object.entries(groupedDates).map(([year, months]) =>
                Object.entries(months).map(([month, days]) =>
                  renderGanttMonthDayDiv(year, month, days),
                ),
              )}
            </div>
            <div className="gantt-timeline-body" ref={timelineBodyRef}>
              {/* Commented out until fixed properly */}
              {/* {getTodayLinePosition() !== null && (
              <div
              className="gantt-today-line"
  style={{
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: `${getTodayLinePosition() + 10 }px`,
    width: '2px',
    backgroundColor: 'red',
    zIndex: 10,
    transform: 'translateX(-50%)',  // This centers the line on its position
  }}
              ></div>
            )} */}{' '}
              {/* Add ref here */}
              {flatData.map(
                (item) =>
                  item.isVisible && (
                    <div key={item.id + '-' + item.type} className="gantt-row">
                      <div className="gantt-bar-container">
                        {getBarStyle(item) && (
                          <div
                            className="gantt-bar"
                            style={getBarStyle(item)}
                            onMouseMove={(e) => handleMouseMove(e, item)}
                            onMouseLeave={() => {
                              setHoveredItemId(null)
                            }}
                          ></div>
                        )}
                      </div>
                    </div>
                  ),
              )}
              {hoveredItemId && (
                <div
                  className="gantt-tooltip"
                  style={{
                    top: `${tooltipPosition.top}px`,
                    left: `${tooltipPosition.left}px`,
                  }}
                >
                  <div className="gantt-bar-tooltip-content">
                    <div className="gantt-tooltip-row">
                      <span className="gantt-tooltip-label">{t('ganttChartModal.start')}:</span>
                      <span className="gantt-tooltip-text">
                        {flatData.find((item) => item.id === hoveredItemId)?.startDate ||
                          t('ganttChartModal.notSet')}
                      </span>
                    </div>
                    <div className="gantt-tooltip-row">
                      <span className="gantt-tooltip-label">{t('ganttChartModal.end')}:</span>
                      <span className="gantt-tooltip-text">
                        {flatData.find((item) => item.id === hoveredItemId)?.endDate ||
                          t('ganttChartModal.notSet')}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .gantt-chart {
          font-family: Arial, sans-serif;
          width: 100%;
          overflow: hidden;
          position: relative;
        }
        .gantt-controls {
          margin-bottom: 10px;
          position: sticky;
          top: 0;
          background-color: white;
          z-index: 100;
          display:flex;
          flex-direction:row;
        }
        .gantt-controls select,
        .gantt-controls input {
          margin-right: 10px;
        }
        .gantt-content {
          display: flex;
          width: 100%;
          height: 70vh;
          overflow: hidden;
        }
        .gantt-task-list {
          width: 250px;
          flex-shrink: 0;
          border-right: 1px solid #ccc;
          background-color: white;
          position: sticky;
          left: 0;
          z-index: 50;
          overflow-y: scroll;
          height: 100%;
          scrollbar-width: none;  /* Firefox */
          -ms-overflow-style: none;  /* Internet Explorer 10+ */
        }
        .gantt-task-list::-webkit-scrollbar { 
          width: 0;
          height: 0;
          background: transparent;  /* Chrome/Safari/Webkit */
        }
        .gantt-title {
          height: 40px;
          line-height: 40px;
          padding: 0 10px;
          border-bottom: 1px solid #ccc;
          font-weight: bold;
          position: sticky;
          top: 0;
          background-color:white;
        }
        .gantt-task-item {
          height: 40px;
          line-height: 40px;
          padding: 0 10px;
          border-bottom: 1px solid #eee;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .gantt-timeline {
          flex-grow: 1;
          overflow-y: scroll;
          height: 100%;
        }
        .gantt-timeline-inner {
          min-width: 100%;
        }
        .gantt-header {
          display: flex;
          height: 40px;
          border-bottom: 1px solid #ccc;
          overflow-x: hidden;
          overflow-y: hidden;
          position: sticky;
          top: 0;
          z-index:100;
        }
        .gantt-row {
          height: 40px;
          border-bottom: 1px solid #eee;
          position: relative;
        }
        .gantt-bar-container {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
        .gantt-bar {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          transition: all 0.3s ease;
        }
        .gantt-bar:hover {
          filter: brightness(1.1);
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        }
        .collapse-icon {
          margin-right: 5px;
          cursor: pointer;
        }
        .gantt-timeline-body {
          position: relative;
        }
        .gantt-task-tooltip {
          position: absolute;
          z-index: 9999;
          pointer-events: none;
          white-space: nowrap;
          word-wrap: break-word; /* Allow long words to break */
        }
        .gantt-tooltip {
          position: absolute;
          background-color: white;
          border: 1px solid #ccc;
          padding: 5px 10px;
          border-radius: 5px;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
          z-index: 101;
          pointer-events: none;
          width: 200px;
          max-width: 200px;
          box-sizing: border-box;
        }
        .gantt-bar-tooltip-content {
          width: 100%;
        }
        .gantt-task-tooltip-content {
          position: fixed;
          border: 1px solid #ccc;
          padding: 5px 10px;
          border-radius: 5px;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
          background-color:white;
          index-z:999:
        }
        .gantt-tooltip-row {
          width: 100%;
          display: flex;
          align-items: center;
        }
        .gantt-tooltip-label {
          font-weight: bold;
          margin-right: 5px;
          display: inline-block;
        }
        .gantt-tooltip-text {
          display: inline-block;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .gantt-month {
          height: 20px;
          line-height: 20px;
        }
        .gantt-days {
          height: 20px;
        }
        .gantt-day {
          box-sizing: border-box;
          background-color:white;
        }
        .gantt-day::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          width: 1px;
          background-color: #ccc;
        }
        .gantt-day.today {
          background-color: lightgreen;
        }
      `}</style>
    </div>
  )
}

export default GanttChart
